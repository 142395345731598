module Balkanica.RhythmLibrary

open Balkanica.DrumPattern

/// Supported sigantures
let supportedSignatures =
    [|
        "5/8"
        "6/8"
        "7/8"
        "4/4"
        "9/8"
        "10/8"
        "11/8"
       // "12/8"   // same as 6/4 (or 6/8 dt)
        "13/8"
        //"14/8"  // same as 7/4 (or 7/8 dt)
        "15/8"
        //"16/8"  // same as 8/4 (or 8/8 dt)
        "17/8"
        //"18/8"  // same as 9/4 (or 9/8 dt)
        "19/8"
        //"20/8"  // same as 10/4 (or 10/8 dt)
    |]

let create region (name, pattern, tempo, aliases, links, notes) =
    let pattern =
        {
            Pattern = pattern
            Signature = {Numerator = pattern.Length; Denominator = Eight}
            Grid = Eight
            Tempo = tempo
        }
    let meta =
        {
            Meta.Create(name) with
                Alias = Array.ofSeq aliases
                Info = links
                Notes = notes
                Region = region
        }

    if not pattern.IsValid then
        failwith "Problem"
    pattern, meta


let middleEasterRhythms =
    let doubleTime x = $"{x}/4 rhythm interpreted as double-time {2*x}/4."
    let halfTime x = $"{x}/4 rhythm interpreted as half-time {x}/8."
    let rescale x = $"{x}/4 rhythm written as {2 * x}/8."
    let repeatedBars = "two repeated bars"

    let create (name, pattern, tempo, aliases, link, notes) =
        let links =
            if link = "" then Array.empty else [|link|]
        
        create MiddleEast (name, pattern, tempo, aliases, links, notes)

    let create2 (name, pattern, tempo, (aliases:string seq), link, notes, tutorials) =
        let pattern, meta = create (name, pattern, tempo, aliases, link, notes)
        pattern, {meta with Tutorials = Array.ofSeq tutorials}

    let create3 (name, pattern, tempo, (aliases:string seq), link, notes, videos) =
        let pattern, meta = create (name, pattern, tempo, aliases, link, notes)
        pattern, {meta with Videos = Array.ofSeq videos}


    [
        // 2/4
        create3("Ayyub", "DoDTDoDT",  150.0, [|"Ayub"; "Ayoub"|], "https://www.maqamworld.com/en/iqaa/ayyub.php", "", ["https://www.youtube.com/embed/rjMt9Ewk4PQ"; "https://www.youtube.com/embed/26cjsZDL7QU"])
        create3("Ayyub", "DoooDoTo",  150.0, [|"Ayub"; "Ayoub"|], "https://www.maqamworld.com/en/iqaa/ayyub.php", doubleTime 2, ["https://www.youtube.com/embed/rjMt9Ewk4PQ"; "https://www.youtube.com/embed/26cjsZDL7QU"])
        create3("Malfuf", "DooTooTo", 150.0, ["Laff"], "https://www.maqamworld.com/en/iqaa/malfuf.php", doubleTime 2, ["https://www.youtube.com/embed/xW3C9IKHxmo"]) //  (16th as  8th)

        create2("čoček", "DooTDoTo", 150.0, ["Kiuchek"], "", "", []) // NEEDS REFERENCE

        create("Fallahi", "DKTKDoTo",   150.0, [], "https://www.maqamworld.com/en/iqaa/fallahi.php", doubleTime 2)
        create("Fox", "DTDTDTDT", 150.0, [], "https://www.maqamworld.com/en/iqaa/fox.php", repeatedBars)
        create("Fox", "DoToDoTo", 150.0, [], "https://www.maqamworld.com/en/iqaa/fox.php", doubleTime 2)
        create("Karachi", "ToTDToTD", 150.0, [], "https://www.maqamworld.com/en/iqaa/karachi.php", repeatedBars)
        create("Karachi", "ToooToDo", 150.0, [], "https://www.maqamworld.com/en/iqaa/karachi.php", doubleTime 2)
        create("Wahda Saghira", "DoTTDoTT", 150.0, ["Wahda Basita"], "https://www.maqamworld.com/en/iqaa/wahda_saghira.php", repeatedBars)
        create("Wahda Saghira", "DoooToTo", 150.0, ["Wahda Basita"], "https://www.maqamworld.com/en/iqaa/wahda_saghira.php", doubleTime 2)

        // 4/4
        create2("Baladi",  "DDoTDoTo",  150.0, ["Masmudi Saghir"], "https://www.maqamworld.com/en/iqaa/baladi.php", "", ["https://youtu.be/b9RxwQqTzGY"])
        create("Hacha‘",  "DoTTToTT",   150.0, [], "https://www.maqamworld.com/en/iqaa/hachaa.php", "")
        create("Maqsum‘", "DToTDoTo",   150.0, [], "https://www.maqamworld.com/en/iqaa/maqsum.php", "")
        create2("Sa‘idi",  "DToDDoTo",  150.0, [], "https://www.maqamworld.com/en/iqaa/saidi.php", "", ["https://youtu.be/47bHu8LL40w"])
        create("Nawari",  "TDoTDoTo",   150.0, ["Katakufti"], "https://www.maqamworld.com/en/iqaa/nawari.php", "")
        create("Rumba",   "DooTooDo",   150.0, [], "https://www.maqamworld.com/en/iqaa/rumba.php", "")
        create("Wahda",   "DooTooTo",   150.0, [], "https://www.maqamworld.com/en/iqaa/wahda.php", "")
        create("Katakufti", "DTTDoToT", 150.0, [], "https://www.maqamworld.com/en/iqaa/katakufti.php", "")

        // 8/4
        create("Bambi",         "DooTooTooTooDoDo", 150.0, ["Masmudi Saghir"], "https://www.maqamworld.com/en/iqaa/bambi.php", "")
        create("Ciftetelli",    "DoTToTToDoDoTooo", 150.0, ["Shaftatalli"], "https://www.maqamworld.com/en/iqaa/ciftetelli.php", "")
        create("Zaffa",         "DoTKToToDoToTooo", 150.0, [], "https://www.maqamworld.com/en/iqaa/zaffa.php", doubleTime 4)
        create("Mukhammas",     "DoDoDoToToTTDoTT", 150.0, [], "", "Normally annotated in 16/4, this rhythm is interpreted here as half-time 8/4.")
        create("Masmudi Kabir", "DoDoooToDoooToTo", 150.0, [], "https://www.maqamworld.com/en/iqaa/masmudi_kabir.php", "")
        create("Wahda Kabira",  "DooTooToDoooTooo", 150.0, ["Wahda Sunbati"], "https://www.maqamworld.com/en/iqaa/wahda_kabira.php", "")
        create("Wahda w-Nuss",  "DooTooToDoooTooo", 150.0, [], "https://www.maqamworld.com/en/iqaa/wahda_w_nuss.php", doubleTime 4)


        // 5/8
        create("Thurayya",  "DoToT", 150.0, ["Aqsaq Turki"], "https://www.maqamworld.com/en/iqaa/thurayya.php", "")

        // 6/8
        create("Yuruk Semai",         "DTTDTo", 150.0, [], "https://www.maqamworld.com/en/iqaa/yuruk_semai.php", "")
        create("Sama‘i Saraband",     "DTTDTT", 150.0, ["Sama‘i Ta’ir"], "https://www.maqamworld.com/en/iqaa/samai_saraband.php", repeatedBars)
        create("Sama‘i Saraband",     "DoToTo", 150.0, ["Sama‘i Ta’ir"], "https://www.maqamworld.com/en/iqaa/samai_saraband.php", doubleTime 3)
        create("Sama‘i Darij",        "DTTDTT", 150.0, [], "https://www.maqamworld.com/en/iqaa/samai_darij.php", "Normally annotated in 3/4, this rhythm is interpreted here as two bars of double-time 6/8.")
        create("Sama‘i Darij",        "DoToTo", 150.0, [], "https://www.maqamworld.com/en/iqaa/samai_darij.php", "Normally annotated in 3/4, this rhythm is interpreted here as quad-time 6/8.")

        // 7/8
        create("Dawr Hindi",    "DTTDoTo",    150.0, [], "https://www.maqamworld.com/en/iqaa/dawr_hindi.php", "")
        create("Nawakht",       "DoTDoTT",    150.0, [], "https://www.maqamworld.com/en/iqaa/nawakht.php", halfTime 7)

        // 9/8
        create("Aqsaq",        "DoToDoToT", 150.0, [], "https://www.maqamworld.com/en/iqaa/aqsaq.php", "")
        create("9-ka Kuchek",  "DooTDooT*", 150.0, [], "", "") //TODO: References
        
        // 10/8
        create("Jurjina",           "DoTToDoToo", 150.0, [], "https://www.maqamworld.com/en/iqaa/jurjina.php", "")
        create("Sama‘i Thaqil",     "DooToDDToo", 150.0, [], "https://www.maqamworld.com/en/iqaa/samai_thaqil.php", "")
        create("Mudawwar Shami",    "ToDoToDDDo", 150.0, [], "https://www.maqamworld.com/en/iqaa/mudawwar.php", halfTime 10)
          
        // 11/8
        create("‘Awis",  "DoTDoTTDDoT", 150.0, [], "https://www.maqamworld.com/en/iqaa/awis.php", "")

        // 12/8
        create("Sudasi",            "DKTDDKTDDoTo", 150.0, [], "https://www.maqamworld.com/en/iqaa/sudasi.php", rescale 6) // Ks are double 16ths
        create("Mudawwar Masri ",   "ToDoToDDDoTo", 150.0, [], "https://www.maqamworld.com/en/iqaa/mudawwar.php", halfTime 12)

        // 13/8
        create("Dharafat",  "DooTooDoDDToo", 150.0, ["Zarafat"], "https://www.maqamworld.com/en/iqaa/dharafat.php", "")
        create("Murabba‘",  "DTTDoToToTTDo", 150.0, [], "https://www.maqamworld.com/en/iqaa/murabbaa.php", halfTime 13)
        
        //14/8
        create("Muhajjar",  "DDDoToDoToToTT", 150.0, [], "https://www.maqamworld.com/en/iqaa/muhajjar.php",  halfTime 14)

        // 17/8
        create("Khosh Rang",  "DoTDoDDToTToDDToT", 150.0, ["Khush Rank"], "https://www.maqamworld.com/en/iqaa/khosh_rang.php", "")

        //19/4
        create("Awfar Masri",  "DoDoooToToDoDToTooo", 150.0, [], "", halfTime 19) //https://www.youtube.com/watch?v=BkGIL1ijD3c


        // Not supported
        //32/4
        //Meta.Create("Warshan ‘Arabi", "", [], "https://www.maqamworld.com/en/iqaa/warshan_arabi.php", "")
        //Meta.Create("Sittatu ‘Ashar Masri", "", [], "https://www.maqamworld.com/en/iqaa/sittatu_ashar.php", "")
        
        //20/4
        //Meta.Create("Fakhit ",  "", [], "https://www.maqamworld.com/en/iqaa/fakhit.php", "")

    ]


let balkanRhythms =
    let create (name, pattern, tempo, videos, notes, alias) =
        let pattern, meta = create Balkan (name, pattern, tempo, alias, Array.empty, notes)
        pattern, {meta with Videos = Array.ofSeq videos}



    [
        create("Pajduško horo",         "Doxo*",                        150.0, ["https://www.youtube.com/embed/fESztL_G54A"], "Q+S" ,[])
        create("Račenica",              "Doxoxo*",                      150.0, ["https://www.youtube.com/embed/xenjhHHBZkA"], "Q+Q+S", [])
        create("Makedonsko horo",       "Do*xoxo",                      150.0, ["https://www.youtube.com/embed/IolB6LVhJFE"; "https://www.youtube.com/embed/5u4jOtmmX_M"], "S+Q+Q", ["Četvorno"; "Male Račenica"])
        create("Dajčovo horo",          "Doxoxoxo*",                    150.0, ["https://www.youtube.com/embed/TtnYFUSpp5c"], "Q+Q+Q+S", [])
        create("Kopanica",              "Doxoxo*xoxo",                  150.0, ["https://www.youtube.com/embed/i8Ao1o2uw3o"; "https://www.youtube.com/embed/WRdifDUq2EA"], "Q+Q+S+Q+Q", ["Gankino"])
        create("Petrunino horo",        "Do*xoxoxoxo*",                 150.0, ["https://www.youtube.com/embed/dqUe-8WOc2E"; "https://www.youtube.com/embed/ESGWuxnDbXs"], "S+Q+Q+Q+S", [])
        create("Krivo Sadovsko horo",   "Doxoxoxo*xoxo",                150.0, ["https://www.youtube.com/embed/YWY2zct7R-4"], "Q+Q+Q+S+Q+Q", ["Postupano"])
        create("Bučimiš",               "Doxoxoxoxo*xoxo",              150.0, ["https://www.youtube.com/embed/rxiy5ZTzKMI"], "Q+Q+Q+Q+S+Q+Q", [])
        create("Jovino horo",           "Do*xoxoxoxoxo*xoxo",           150.0, ["https://www.youtube.com/embed/S_-6SY7xGNw"], "composite 7/8 + 11/8 or (S+Q+Q)+(Q+Q+S+Q+Q)", ["Jove Malaj Mome"])
        create("Sandansko horo",        "Doxoxoxo*xoxoxoxo*xoxo",       150.0, ["https://www.youtube.com/embed/G5tSEbTsrQM"], "composite 9/8 + 13/8 or (Q+Q+Q+S)+(Q+Q+Q+S+Q+Q)", [])
        create("Sedi Donka",            "Do*xoxoxo*xoxoxoxoxo*xoxo",    150.0, ["https://www.youtube.com/embed/YG5MNYfJWmQ"], "composite 7/8 + 11/8 + 7/8 or (S+Q+Q)+(S+Q+Q+Q+Q)+(S+Q+Q)", [])
        create("Biala Roza",            "Doxoxoxxo",                    150.0, ["https://www.youtube.com/embed/4-9H9DTj_R0"], "", [])
        create("Elenino horo",          "Doxoxxo",                      150.0, ["https://www.youtube.com/embed/3TjTfCiomhc"], "", ["Eleno mome"])
    ]


let rhythmsLibrary =
    [
        middleEasterRhythms
        balkanRhythms
    ]
    |> Seq.concat

