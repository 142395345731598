module Balkanica.UI
open Balkanica.DrumPattern
open Fable.React
open Fulma
open Fable.FontAwesome
open Balkanica.Model

open Feliz
open Feliz.Bulma
open Fable.Core
open Fable.Core.JsInterop

module Core =   
    [<Emit("""navigator.userAgent""")>]
    let isMobileDevice() : string = jsNative
        
    /// FontAwesome button
    let faButton isEnabled icon action title =
        let props =
            if isEnabled then 
                [
                    Props.Style [ Props.Cursor "pointer"] :> Props.IHTMLProp
                    Props.Title title
                    Props.OnClick (fun _ -> action()) 
                ]
            else
                [
                    Props.Title title
                    Props.Style [Props.Color "gray"]
                ]
        a props [ Icon.icon [] [ Fa.i [icon] [] ] ]


    let faButtonAbsolute (right:int) (top:int) (fontSize:int) icon action =
        Bulma.block
            [
                prop.style [
                    style.cursor.pointer
                    style.fontSize fontSize
                    style.position.absolute
                    style.right right
                    style.top top
                    style.zIndex 1
                    ]
                //prop.title "Close"
                prop.onClick action
                prop.children [
                    Bulma.icon [Fa.i [icon] []]
                ]
            ]

    let faButtonInADiv isActive icon f label =
        Bulma.field.div [
            prop.style [style.margin 10]
            prop.children [
                faButton isActive icon f label
            ]
        ]

    let spinner =
        Bulma.field.div [
            prop.style [style.margin 10]
            prop.children [
                Fa.i [Fa.Solid.Spinner; Fa.Spin] []
            ]
        ]        

    let faLink icon src =
        let props =  
            [
                Props.Style [ Props.Cursor "pointer"] :> Props.IHTMLProp
                Props.Title src
                Props.Href src
                Props.Target "_blank"
            ]
        a props [
            Icon.icon [Icon.Option.Props [Props.Style [Props.CSSProp.PaddingRight 10]]] [Fa.i [icon] []]
            str src
            ]

    /// HTML table with headers built using a projection function
    let table headers (view: 'a -> string -> ReactElement) (elements:'a seq) =
        let headersHtml =
            headers
            |> Seq.map (fun h -> th [] [str h])
            |> Fable.React.Standard.tr []
            |> List.singleton
    
        elements
        |> Seq.map (fun element ->
            headers
            |> Seq.map (fun header ->
                td [] [view element header]
    
            )
            |> Fable.React.Standard.tr []
        )
        |> Seq.append headersHtml
        |> tbody []
        |> List.singleton
        |> Fulma.Table.table []


    /// Dropdown with a given label and set of values
    let dropdown label (values:seq<string>) (current:string) handler =
        Dropdown.dropdown [ Dropdown.IsHoverable
                             ] [
            Dropdown.trigger [] [
                Button.button [] [
                    span [] [ str label]
                    Icon.icon [ Icon.Size IsSmall ] [
                        Fa.i [ Fa.Solid.AngleDown ] []
                    ]
                ]
            ]
            Dropdown.menu [  ] [
                Dropdown.content [] [
                    for m in values ->
                     Dropdown.Item.a [Fulma.Dropdown.Item.IsActive (m = current); Dropdown.Item.Props[ Props.OnClick (fun _ -> handler m)  ] ] 
                         [ str m]
                ]
            ]
        ]


    /// Simple Bulma button
    let button (label:string) onclick =
        Bulma.button.a [
            prop.onClick onclick
            prop.text label
        ]

    /// Download link for a file
    let download label filename (content:byte[]) =
        let contentBase64 = System.Convert.ToBase64String content
        a [Props.Style [Props.CSSProp.TextAlign Props.TextAlignOptions.Center]; Props.Download filename; Props.Href $"data:text/plain;base64,{contentBase64}"]
            [ Icon.icon [] [ Fa.i [Fa.Solid.Download; Fa.Size Fa.FaLarge] []; str label] ]

    let errorBox (clearError: System.Guid -> unit) (guid:System.Guid, msg:string) =
        let clearButton =
            Bulma.button.button [prop.onClick (fun _ -> clearError guid); prop.children [str "Clear"]]

        Bulma.columns [
            columns.isMobile
            color.hasBackgroundDanger
            prop.style [style.marginLeft 20; style.marginRight 20]
            prop.children [
                Bulma.column [prop.children [str msg]]
                Bulma.column [column.is3; prop.children [clearButton]]
            ]
        ]
        
    let radio (options:seq<'a>) (selected:'a) select =
        let name = System.Guid.NewGuid().ToString()
        let isSelected o = selected = o
        options
        |> Seq.map(fun (o:'a) -> Bulma.label [str (o.ToString()); br []; Bulma.input.radio [prop.isChecked (isSelected o); prop.onClick (fun _ -> select o); prop.name name]])
        |> Bulma.level


    let modal (close: (unit -> unit) option) (contents:seq<ReactElement>) =
        let contentsWithClose =
            if close.IsSome then
                let closeButton = faButtonAbsolute 15 1 18 Fa.Solid.Times (fun _ -> close.Value())                   
                Seq.append [closeButton] contents
            else
                contents


        Bulma.modal [
            Bulma.modal.isActive
            prop.children [
                Bulma.modalBackground []
                Bulma.modalContent [                  
                    prop.style [                        
                        style.paddingLeft 5
                        style.paddingRight 5
                        style.overflowX.hidden                        
                    ]
                    prop.children [
                        Bulma.box contentsWithClose//[prop.style [style.backgroundColor "#02738C"]; prop.children ]
                        
                    ]
                ]
            ]
        ]

           
module DesktopControls =    
    /// Displays a pattern as an HTML table. The pattern can be editable if an update function (for setting a given char at a specific location) is provided.
    let patternEditor isSmall (template:string) =
        let size = (if isSmall then 100.0  else 200.0) / (float template.Length) ** 0.6 |> int
        let style color =
            [
                Props.CSSProp.Width size
                Props.CSSProp.Height size
                Props.BackgroundColor color
                Props.Color "black"
                //Props.Border "1px solid black"
                Props.CSSProp.FontSize (size / 2)
                Props.TextAlign Props.TextAlignOptions.Center
                Props.FontWeight "bold"
            ]
            |> Props.Style
           
        template
        |> Seq.mapi (fun i c ->
            let color =
                match c with
                | '*' -> "white"
                | '.' -> "gray"
                | 'D' -> "red"
                | 'T' -> "blue"  
                | 'K' -> "yellow"
                | 'R' -> "yellow"
                | 'x' -> "purple"
                | 'o' -> "pink" 
                | _ -> failwithf "Unknown template symbol %c" c

            let displayString =
                match c with
                | 'R' -> "kk"
                | 'K' -> "k"
                | _ -> string c
            td [style color] [str displayString]
            )
        |> Fable.React.Standard.tr [style "white"]
        |> List.singleton
        |> tbody []
        |> List.singleton
        |> Bulma.table

    /// A menu-based browser for annotated rhythms
    let rhythmsBrowser (searchPattern:Pattern option) (selectedSignature: Signature option) (selectSignature: Signature -> unit) (selected:Pattern option) (selectPattern: Pattern -> unit) =
        let isSelected (p:Pattern) =
            
            // if we are showing search results, then could match multiple patterns
            if searchPattern.IsSome then
                Pattern.Intersect p searchPattern.Value
            else
                selected.IsSome &&  p.Pattern = selected.Value.Pattern

        let headers =
            Menu.Item.li [] [
               Bulma.columns [
                   prop.style [style.textAlign.center; style.fontWeight.bold]
                   prop.children [
                       Bulma.column [column.is2; prop.children [str "Name"]]
                       Bulma.column [str "Pattern"]
                       Bulma.column [column.is1; prop.children [str "Grid Value"]]
                       Bulma.column [column.is1; prop.children [str "Tempo (bpm)"]]
                       Bulma.column [column.is1; prop.children [str "Origin"]]
                       Bulma.column [column.is2; prop.children [str "Notes"]]
                       Bulma.column [column.is1; prop.children [str "External Info"]]
                       Bulma.column [column.is1; prop.children [str "Tutorials"]]
                       Bulma.column [column.is1; prop.children [str "Examples"]]
                   ]
               ]
            ]

        let metaToHtml (pattern:Pattern, meta:Meta) =
            let linksToContainer icon (x:string[]) =
                x
                |> Array.map (Core.faLink icon >> Bulma.levelItem)
                |> Bulma.level
                |> Array.singleton
                
            Bulma.columns [
               
                Bulma.column [column.is2; prop.children [str meta.Name]]
                Bulma.column (if pattern.Pattern.Replace("*","").Trim() <> "" then [prop.style [style.overflowX.hidden]; prop.children [patternEditor true pattern.Pattern]] else [])
                Bulma.column [column.is1;  prop.style [style.textAlign.center]; prop.children [str $"1/{pattern.Grid.value}"]]
                Bulma.column [column.is1;  prop.style [style.textAlign.center]; prop.children [str $"%.1f{pattern.Tempo}"]]
                Bulma.column [column.is1;  prop.style [style.textAlign.center]; prop.children [str (meta.Region.ToString())]]
                Bulma.column [column.is2;  prop.children [str meta.Notes]]
                Bulma.column [column.is1;  prop.style [style.textAlign.center]; prop.children (linksToContainer Fa.Solid.Info meta.Info)]
                Bulma.column [column.is1;  prop.style [style.textAlign.center]; prop.children (linksToContainer Fa.Solid.Music meta.Tutorials)]
                Bulma.column [column.is1;  prop.style [style.textAlign.center]; prop.children (linksToContainer Fa.Solid.Music meta.Examples)]
            
            ]


        let menuItem (pattern:Pattern, meta:Meta) =
            Menu.Item.li [ Menu.Item.IsActive (isSelected pattern); Menu.Item.OnClick (fun _ -> selectPattern pattern)] [metaToHtml (pattern, meta)]
    
        let subMenu isActive label action children =
            li [ ]
               [ Menu.Item.a [ Menu.Item.OnClick action; Menu.Item.IsActive isActive ] [ str label ]
                 ul [ ]
                    children ]
   

        Balkanica.RhythmLibrary.rhythmsLibrary
        |> Seq.toList
        |> List.groupBy (fun (p, _) -> p.Signature)
        |> List.sortBy fst
        |> List.map(fun (signature, patterns) ->
    
            let isExpanded = true//(searchPattern.IsNone && selectedSignature.IsSome && selectedSignature.Value = signature) || (patterns |> Seq.map fst |> Seq.exists isSelected)
    
            let items =
                if isExpanded then 
                    patterns
                    |> List.sortBy (fun (p,_) -> p.Complexity)
                    //|> List.append <| [{Pattern = String.replicate (signature.Length / 2) "*"; Signature = signature; Grid = Eight}, Meta.Create "Unlabeled rhythms"]
                    |> List.map menuItem
                    |> List.append [headers]
                else
                    []

            let s = subMenu false ("Time signature " + signature.ToString()) (fun _ -> selectSignature signature) items
            Menu.list [] [s]
       
            )
        |> Menu.menu []

    /// A menu-based browser for pattern variations
    let variationsBrowser
        (variations:Pattern[] option)
        (subVariations:Pattern[] option)   
        (selectedVariation:Pattern option)
        (selectedSubVariation:Pattern option)
        (selectVariation: Pattern -> unit)
        (selectSubVariation: Pattern -> unit) =

        
        let isSelectedVariation (pattern:Pattern) = selectedVariation.IsSome && pattern.Pattern = selectedVariation.Value.Pattern

        let headers =
            Bulma.columns [
                prop.style [style.textAlign.center; style.fontWeight.bold]
                prop.children [
                    Bulma.column [str "Pattern"]
                    Bulma.column [column.is1; prop.children [str "Grid Value"]]
                ]
            ]

        let variationToHtml (variation:Pattern) =
            Bulma.columns [
                Bulma.column [patternEditor true variation.Pattern]
                Bulma.column [column.is1; prop.style [style.textAlign.center]; prop.children [str $"1/{variation.Grid.value}"]]
            ]

        /// deals with sub-variations (e.g. 1/16 notes)
        let menuItem (pattern:Pattern) =
            let action = (fun _ -> selectSubVariation pattern)
            let isSelected = selectedSubVariation.IsSome && pattern.Pattern = selectedSubVariation.Value.Pattern
            Menu.Item.li [ Menu.Item.IsActive isSelected; Menu.Item.OnClick action] [variationToHtml pattern]

        // deals with variations (e.g. 1/8 notes)
        let subMenu pattern children =
            let action = (fun _ -> selectVariation pattern)
            li [ ]
               [ Menu.Item.a [ Menu.Item.OnClick action; Menu.Item.IsActive (isSelectedVariation pattern)] [variationToHtml pattern]
                 ul [ ]
                    children ]

        variations
        |> Option.defaultValue Array.empty    
        |> Seq.toList
        |> List.sortBy (fun variation -> variation.Complexity)
        |> List.map(fun variation ->
            let items =
                if isSelectedVariation variation && subVariations.IsSome && not (Array.isEmpty subVariations.Value) then
                    subVariations.Value
                    |> Array.sortBy (fun subvariation -> subvariation.Complexity)
                    |> Array.map menuItem
                    |> List.ofArray
                    |> List.append [headers]
                else
                    []
            Menu.list [] [subMenu variation items]   
            )
        |> List.append [headers]
        |> Menu.menu []


module ResponsiveControls =
    /// An SVG-based pattern viewer for single patterns
    let patternViewer (pattern:string) =
        let gridSize = 30.0

        let children =
            pattern
            |> Seq.mapi (fun i c ->
                let color =
                    match c with
                    | '*' -> "#514438"
                    | '.' -> "#FFEBCD"
                    | 'D' -> "#C35452"
                    | 'T' -> "#02738C"  
                    | 'K' -> "#F2A343"
                    | 'R' -> "#F2A343"
                    | 'x' -> "#539455"
                    | 'o' -> "#71CDF2" 
                    | _ -> failwithf "Unknown template symbol %c" c

                let displayString =
                    match c with
                    | 'R' -> "kk"
                    | 'K' -> "k"
                    | '.' -> ""
                    | '*' -> "?"
                    | _ -> string c


                let x = gridSize * (float i)
                let cx = x + gridSize / 2.0
                let cy = gridSize/2.0
                let r = 0.90 * gridSize/2.0
                let y =
                    // shift the letters down for capitals to align better
                    match displayString with
                    | "D" | "T" | "?" -> cy + 0.2 * r
                    | "k" | "kk" -> cy + 0.1 * r
                    | _ -> cy 
                [
                    Svg.circle [svg.cx cx; svg.cy cy; svg.r r; svg.fill color; svg.stroke "black"]
                    Svg.text [svg.x cx; svg.y y; svg.textAnchor.middle; svg.alignmentBaseline.middle; svg.text displayString; svg.fill "white"; svg.fontSize 20]
                ]
            )
            |> Seq.concat
            |> List.ofSeq

        Svg.svg [
            //svg.viewBox (- int gridSize, 0, (pattern.Length + 2) * int gridSize, int gridSize)
            svg.viewBox (0, 0, pattern.Length * int gridSize, int gridSize)
            svg.children children                        
            ]
        |> Bulma.field.div

    ///// Shows a spinner as a modal box
    //let waitingModal =
    //    Bulma.field.div [
    //        prop.style [style.textAlign.center; style.padding 100] 
    //        prop.children [
    //            Fa.i [Fa.Size Fa.Fa8x; Fa.Solid.Spinner; Fa.Spin] []
    //        ]
    //    ]
    //    |> List.singleton
    //    |> Core.modal None 


    let patternInfoModal (pattern:Pattern, meta:Meta) close =        
        let mkList items =
            Menu.menu [] [
                for link in items do
                    Menu.Item.li [] [
                        Core.faLink Fa.Solid.FileAudio link
                    ]               
            ]

        let aliases =
            if Array.isEmpty meta.Alias then
                ""
            else
                String.concat ", " meta.Alias
                |> sprintf " It is also known as %s."

        let originString =
            match meta.Region with
            | Balkan -> "a rhythm from the Balkans"
            | MiddleEast -> "a rhythm from the Middle East"
            | World -> "a world rhythm"
            | Western -> "a western rhythm"



        [
            if not (Array.isEmpty meta.Videos) then
                Bulma.block [
                    prop.style [style.textAlign.center]
                    prop.children [
                        iframe [Props.Src meta.Videos.[0]] []
                    ]
                ]

            Bulma.label $"{meta.Name} ({pattern.Signature.ToString()})"

            patternViewer pattern.Pattern

            Bulma.menuLabel "Information"
            str $"{meta.Name} is {originString}.{aliases}" //A typical tempo is %.0f{pattern.Tempo} bpm"

            if not (Array.isEmpty meta.Info) then
                Bulma.menuLabel "External resources"
                mkList meta.Info

            if not (Array.isEmpty meta.Examples) then
                Bulma.menuLabel "Examples"
                mkList meta.Examples

            if not (Array.isEmpty meta.Tutorials) then
                Bulma.menuLabel "Tutorials"
                mkList meta.Tutorials
            
        ]
        |> Core.modal (Some close)
        

    let searchBar update search =
        let searchButton = Core.faButtonAbsolute 25 1 24 Fa.Solid.Search search

        Bulma.navbar [
            prop.style [
                style.paddingLeft 5
                style.paddingRight 10               
                ]
            prop.children [
                Bulma.input.text [
                    prop.style [style.borderRadius 15]
                    prop.onInput (fun x -> update x.Value)
                ]
                searchButton
            ]
        ]

    /// Search bar and settings button
    let controlAndSearchBar
        isWaiting
        isAudioLoaded
        isAudioPlaying
        update
        search
        settings
        toggleAudioPlaying =


        let contextButton =
            if isWaiting then
                Core.spinner
            else
                let audioButton, audioText =
                    if isAudioPlaying then
                        Fa.Solid.Stop, "Stop"
                    else
                        Fa.Solid.Play, "Play"

                Core.faButtonInADiv isAudioLoaded audioButton (fun _ -> isAudioPlaying |> not |> toggleAudioPlaying) audioText
         
        Bulma.field.div [
            prop.style [style.display.flex]//; style.marginLeft 20]
            prop.children [
                contextButton
                Bulma.input.text [prop.onInput (fun x -> update x.Value)]
                Core.faButtonInADiv true Fa.Solid.Search search "Search"
                Core.faButtonInADiv true Fa.Solid.Wrench settings "Settings"
            ]
        ]                          
        
    let settingsScreen (currentSettings:Settings) update exitSettings =       
        let instrumentSelector =
            Bulma.select [
                prop.onChange (fun (x:string) ->
                    let instrument =
                        match x with
                        | "Darbuka" -> Darbuka
                        | "Davul" -> Davul
                        | _ -> failwith "Error"
                    update {currentSettings with Instrument = instrument})

                let mkOpt instrument =
                    let isSelected = currentSettings.Instrument = instrument
                    Html.option [prop.selected isSelected; prop.children [str (string instrument)]]


                prop.children [
                    mkOpt Darbuka
                    mkOpt Davul
                ]
            ]

        let metronomeCheckbox =
            Bulma.input.checkbox [
                prop.isChecked currentSettings.Metronome
                prop.onChange (fun (x:bool) ->
                    update {currentSettings with Metronome = not currentSettings.Metronome})
            ]

        let tempoSelector =
            let parse x =
                match x with
                | "Default" -> None
                | _ -> Some (float x)


            Bulma.select [
                prop.onChange (fun (x:string) ->                            
                    update {currentSettings with Tempo = parse x})

                let mkOpt value =
                    let isSelected = currentSettings.Tempo = parse value
                    Html.option [prop.selected isSelected; prop.children [str value]]


                prop.children [
                    mkOpt "Default"
                    mkOpt "50.0"
                    mkOpt "75.0"
                    mkOpt "100.0"
                    mkOpt "125.0"
                    mkOpt "150.0"
                    mkOpt "175.0"
                    mkOpt "200.0"
                ]
            ]

        let row (label:string) (control:ReactElement) =
            Bulma.columns [
                columns.isMobile
                prop.children [
                    Bulma.column [Bulma.label label]
                    Bulma.column [control]
                ]                
            ]

        [
            Bulma.menuLabel "Audio settings"
            row "Instrument:" instrumentSelector
            row "Metronome:" metronomeCheckbox
            row "Tempo:" tempoSelector                
        ]
        |> Core.modal (Some exitSettings)
        

    /// Displays rows of different rhythms drawn as a pattern
    let rhythmBrowser        
        (patternsAndMeta:(Pattern * Meta option)[])
        (nowPlayingPattern: Pattern option)
        allowExpanding
        isWaiting
        isPlaying        
        (play: Pattern -> unit)
        (stopAudio: unit -> unit)
        (expand: Pattern -> unit)
        (showInfo: (Pattern * Meta) option-> unit)
        =

        let rows =            
            patternsAndMeta
            |> Seq.toList
            |> List.indexed
            |> List.groupBy (fun (_, (p, _)) -> p.Signature)
            |> List.sortBy fst
            |> List.collect(fun (_, patterns) ->
                patterns
                |> List.map (fun (i, (pattern, metaOpt)) ->
                    let isSelected =
                        nowPlayingPattern.IsSome && nowPlayingPattern.Value.isSubsetOf pattern

                    let isCurrentlyPlaying = isPlaying && isSelected
                       
                    let showInfoButtonEnabled, showInfoButtonAction =
                        match metaOpt with
                        | Some meta -> true, (fun _ -> (pattern, meta) |> Some |> showInfo)
                        | None -> false, ignore

                    let playStopButton =
                        if isSelected && isWaiting then
                            Core.faButton true Fa.Solid.Spinner ignore "Stop"                            
                        elif isCurrentlyPlaying then
                            Core.faButton true Fa.Regular.StopCircle (fun _ -> stopAudio ()) "Stop"
                        else
                            Core.faButton true Fa.Regular.PlayCircle (fun _ -> play pattern ) "Play"

                    let controls =
                        Bulma.field.div [
                            prop.style [
                                style.float'.right
                                style.paddingRight 10
                                ]

                            prop.children [
                                playStopButton
                                Core.faButton showInfoButtonEnabled Fa.Solid.Info showInfoButtonAction "Info"
                                if allowExpanding then Core.faButton true Fa.Solid.ArrowRight (fun _ -> expand pattern ) "Expand"
                            ]
                        ]

                    
                    let info =
                        let title = metaOpt |> Option.map (fun v -> v.Name) |> Option.defaultValue $"Variation {i}"
                        Bulma.label [
                            str title
                            Bulma.field.p [
                                prop.style [style.fontWeight.lighter]
                                prop.children [
                                    Bulma.field.p [prop.style [style.float'.left]; prop.children [str $"{pattern.Signature.ToString()}"]]
                                    Bulma.field.p [prop.style [style.float'.right]; prop.children [str $"{pattern.Tempo} bpm"]]                                
                                    ]
                                ]
                        ]

                                          
                    Bulma.field.div [                       
                        prop.style [
                            style.border (1, borderStyle.solid, "black")
                            style.marginRight 10
                            style.padding 5
                            style.marginLeft 10
                            style.borderRadius 15
                            if isSelected then style.backgroundColor "#E2F5FD"
                            ]
                        prop.children [
                            Bulma.columns [
                                columns.isMobile
                                prop.style [style.marginBottom 0]
                                prop.children [
                                    Bulma.column [prop.children [info]]
                                    Bulma.column [prop.children [controls]]
                                ]
                            ]
                            patternViewer pattern.Pattern                                    
                        ]
                    ]
                )
            )


        Bulma.field.div [
            prop.style [style.overflowY.auto; style.minHeight 500; style.overflowX.hidden]
            prop.children rows
            ]


    /// Embedded audio component with optional download link for MIDI
    let audio enableMidiDownload (response:AudioResponse) =
        let title = $"Playing pattern {response.Pattern}" 
        if Array.isEmpty response.Mp3 then
            Bulma.field.div []
        else
            let contentBase64 = System.Convert.ToBase64String response.Mp3
            let src = $"data:audio/mp3;base64,{contentBase64}"
            let audio = audio [
                Props.Custom ("controlsList", "nodownload")
                Props.Src src
                Props.Controls false
                Props.Title title
                Props.Loop true
                Props.AutoPlay true] []
    
            //if enableMidiDownload then
            //    Bulma.container [
            //        //Bulma.field.p [prop.style [style.paddingTop 20]; prop.children [str $"Currently playing {response.Pattern}"]]
                   
            //        prop.style [style.display.flex]
            //        prop.children [
                            
            //            Bulma.column [column.isTwoThirds; prop.children [audio ]]
            //            Bulma.column [Core.download "MIDI" (response.Pattern + ".mid") response.Midi]
            //        ]                    
            //    ]

            //else
            Bulma.container [
                prop.style [style.position.absolute]
                //Bulma.field.p [prop.style [style.paddingTop 20]; prop.children [str $"Currently playing {response.Pattern}"]]
                prop.children [audio]
            ]



    /// Log-in box with video and info text
    let loginBox (current: Shared.AuthLogin) (update:Shared.AuthLogin -> unit) (submit:Shared.AuthLogin -> unit)  =
        Bulma.heroBody [            
            Bulma.container [
                Bulma.columns [
                    columns.isCentered
                    prop.children [
                    Bulma.column [
                            column.is5                       
                            prop.children [
                                Bulma.field.div [
                                    prop.style [style.textAlign.center]
                                    prop.children [
                                        iframe [Props.Src "https://www.youtube.com/embed/h4EFgbAYzvA"] []
                                    ]
                                ]

                                Bulma.field.div [
                                    prop.style [style.display.flex]
                                    prop.children [
                                        Bulma.label [str "Invitation Code"]
                                        Bulma.input.password [prop.style [style.marginLeft 20; style.marginRight 20]; prop.onInput (fun x -> update {current with Password = x.Value})]
                                        Bulma.button.submit [prop.onClick (fun _ -> submit current)]
                                    ]
                                ]
                                Bulma.text.p [prop.children [str "Encyclopaedia Balkanica is currently available by invitaition only. Please email admin[at]balkanica.co.uk to get in touch and request to join the pre-release program."]]
                            ]
                        ]
                    ]
                ]
            ]
        ]