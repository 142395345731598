module Balkanica.Index

open Balkanica.Model

open Feliz.Bulma
open Fable.React.Helpers
open Feliz

let view (model: Model) (dispatch: Msg -> unit) =
    let errors =
        if model.Errors.IsEmpty then List.empty
        else
            model.Errors
            |> List.map (UI.Core.errorBox (ClearError >> dispatch))
            |> Bulma.field.div
            |> List.singleton


    //let desktopElements = Balkanica.DesktopUI.desktopUI model dispatch
    let deviceInfo = Bulma.field.div [str $"Device: {UI.Core.isMobileDevice()}"]
    let loginBox = UI.ResponsiveControls.loginBox model.LoginDetails (UpdateLoginDetails >> dispatch) (Authenticate >> dispatch)  
    let isMobileDevice =
        let deviceInfoString = UI.Core.isMobileDevice()
        deviceInfoString.Contains("Android") || deviceInfoString.Contains("iPhone")

    let rhythmBrowser =
        let allowExpanding = model.Breadcrumbs.Length < 2 // one or two levels in
        UI.ResponsiveControls.rhythmBrowser
            model.Patterns
            model.NowPlaying
            allowExpanding
            model.IsWaiting
            model.IsAudioPlaying
            (PlayPattern >> dispatch)
            (fun _ -> ToggleAudioPlaying false |> dispatch)
            (Expand >> dispatch)
            (SetInfoPattern >> dispatch)

    let breadcrumbs =
        let element v =           
            [Html.li [Html.a [prop.onClick (fun _ -> v |> Goto  |> dispatch); prop.children [str (string v)]] ]]

        let content = 
            [
                [Html.li [Html.a [prop.onClick (fun _ -> ResetPatterns |> dispatch); prop.children [str "Library"]] ]]
                if model.Breadcrumbs.Length > 0 then element Rhythm
                if model.Breadcrumbs.Length > 1 then element Variation
            ]
            |> List.concat
            |> Html.ul

        Bulma.breadcrumb [
            breadcrumb.isLarge
            prop.style [style.marginBottom 0]
            prop.children [content]
        ]



    let mobileWarning =
        Bulma.field.div [
            color.hasBackgroundDanger
            color.hasTextBlack
            prop.style [style.marginLeft 50; style.paddingLeft 20; style.marginRight 50]
            prop.children [
                Bulma.label "WARNING: Desktop version is currently out-of-date."
            ]
        ]

    let titleBar =
        let settings =
            let icon = Fable.FontAwesome.Free.Fa.Solid.SlidersH
            Bulma.block
                [
                    prop.style [
                        style.cursor.pointer
                        style.float'.right
                        style.paddingRight 10
                        style.fontSize 24
                        style.paddingTop 5
                        ]
                    prop.title "Settings"
                    prop.onClick (fun _ -> true |> ToggleSettingsModal |> dispatch)
                    prop.children [
                        Bulma.icon [Fable.FontAwesome.Fa.i [icon] []]
                    ]
                ]

        Bulma.navbar
            [
                prop.style
                    [
                        style.backgroundColor "#C5DCE1"
                        style.display.block
                    ]

                prop.children
                    [
                        Bulma.image [prop.style [style.floatStyle.left; style.paddingLeft 8; style.paddingTop 5]; image.is48x48; prop.children [Html.img [prop.src "icon2.png"]]]
                        Bulma.label [prop.style [style.floatStyle.left; style.paddingLeft 10; style.paddingTop 4; style.fontFamily "Bell MT"; style.fontSize 30]; prop.children [str "Balkanica"]]      
                        settings                                           
                    ]
            ]






    let screenElements =
        [            
            errors
            if model.IsAuthenticated then
                [
                    if not isMobileDevice then mobileWarning

                    if  model.SelectedPatternForInfo.IsSome then
                        UI.ResponsiveControls.patternInfoModal model.SelectedPatternForInfo.Value (fun _ -> None |> SetInfoPattern |> dispatch)

                    //if model.IsWaiting then
                    //    UI.ResponsiveControls.waitingModal

                    if model.IsSettingsOpen then
                        UI.ResponsiveControls.settingsScreen
                            model.Settings
                            (UpdateSettings >> dispatch)
                            (fun _ -> false |> ToggleSettingsModal |> dispatch)                    


                   
                    titleBar

                    breadcrumbs

                    UI.ResponsiveControls.searchBar (SetSearchString >> dispatch) (fun _ -> dispatch Search) 

                    rhythmBrowser

                    //VexFlowFable.score "patternView"

                    if model.IsAudioPlaying && model.IsAudioLoaded then
                        UI.ResponsiveControls.audio model.Settings.IsMidiDownloadEnabled model.Audio
                   
                       
                ]                    
            else
                [loginBox]


        ]
        |> List.concat

    Bulma.hero [
        //color.isLight
        //color.isDark
        prop.style [
            style.overflow.hidden
            style.height (length.vh 100.0)
            //style.backgroundColor.black
            //style.paddingTop 0
            //style.marginTop 0
                  
        ]
        prop.children screenElements

    ]
    

(*

TODO:
- Info page as modal
- Info link from variations (when possible)

*)