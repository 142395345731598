namespace Balkanica.Shared

open System
open Balkanica.DrumPattern


module Route =
    let builder typeName methodName =
        sprintf "/api/%s/%s" typeName methodName


type AuthLogin =
    {
        Username : string
        Password : string
    }

type IServerApi =
    {
      GenerateVariations: (Pattern * int * bool) -> Async<Result<Pattern[], string>>
      LoadAudio : AudioQuery -> Async<Result<AudioResponse, string>>
      Authenticate : AuthLogin -> Async<Result<unit, string>>
    }