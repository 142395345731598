module Balkanica.Model

open Elmish
open Fable.Remoting.Client
open Balkanica
open Balkanica.Shared
open Balkanica.DrumPattern

type Settings =
    {
        /// If set to true, MIDI is downloadable
        IsMidiDownloadEnabled : bool

        /// The instrumnet used for audio generation
        Instrument: Instrument

        /// Include metronome in the loop
        Metronome : bool

        /// If None, then the default rhythm tempo is used
        Tempo : float option
    }
    static member Init =
        {
            IsMidiDownloadEnabled = false
            Instrument = Davul
            Metronome = true
            Tempo = None
        }

type Breadcrumb =
    | Rhythm
    | Variation

type Model =
    {

        /// Music score to display
        Score : VexFlowScore option

        /// Audio/MIDI to play in the player or download
        Audio : AudioResponse

        /// Pattern that is currently playing
        NowPlaying : Pattern option

        /// stores the state of the pattern in the search bar
        SearchString: string
       
        /// Rhythms/variations/subdivisions to display
        Patterns : (Pattern * Meta option)[]

        /// Current selection at multiple levels (Rhythm > Variation > Subdivision)
        Breadcrumbs : Pattern list       

        /// Stores error
        Errors: (System.Guid * string) list

        /// Stores various settings for look/feel and audio generation
        Settings : Settings

        /// Stores state info for authentication
        LoginDetails : AuthLogin

        IsAuthenticated : bool
        IsSettingsOpen : bool
        IsWaiting : bool
        IsAudioPlaying : bool
        SelectedPatternForInfo : (Pattern * Meta) option
    }

    static member Init =

        {
            Score = None
            Audio = AudioResponse.empty
            SearchString = ""

            Settings = Settings.Init
            Breadcrumbs = List.empty
            NowPlaying = None

            Patterns = Balkanica.RhythmLibrary.rhythmsLibrary |> Seq.toArray |> Array.map (fun (p, m) -> p, Some m)
            Errors =  List.empty

            LoginDetails = {Username = ""; Password = ""}

            IsAuthenticated = false
            IsSettingsOpen = false
            IsWaiting = false
            IsAudioPlaying = false
            SelectedPatternForInfo = None
        }

    member this.IsAudioLoaded =
        this.Audio <> AudioResponse.empty

type Msg =
    /// Sets the music notation in the model to be displayed
    | SetMusicScore of VexFlowScore

    /// Sets the audio in the model to be played
    | SetAudio of AudioResponse

    /// Trigger the playing of a pattern
    | PlayPattern of Pattern

    /// Sets the patterns in the model (could be rhythms, variations, subvariations or search results)
    | SetPatternsAndBreadcrumbs of (Pattern * Meta option)[] * Pattern list

    /// Reset the patterns to the defaults (entire library)
    | ResetPatterns

    /// Generate the varioations or subdivitions
    | Expand of Pattern

    /// Return to a specific breadcrumb
    | Goto of Breadcrumb

    // searching
    | SetSearchString of string
    | Search 

    // Navigation
    | ToggleSettingsModal of bool
    | ToggleAudioPlaying of bool
    | UpdateSettings of Settings
    | SetInfoPattern of (Pattern * Meta) option

    // Authentication
    | Authenticate of AuthLogin
    | ProcessAuthResponse of Result<unit, string>
    | UpdateLoginDetails of AuthLogin

    // Error handling
    | AddError of string
    | ClearError of System.Guid

let server =
    Remoting.createApi ()
    |> Remoting.withRouteBuilder Route.builder
    |> Remoting.buildProxy<IServerApi>

let private generateVariationsResponseHandler breadcrumbs response =
    match response with
    | Ok patterns ->
        let patterns' = patterns |> Array.map (fun p -> p, None)
        SetPatternsAndBreadcrumbs(patterns', breadcrumbs)

    | Error msg -> msg |> AddError

let private generateAudioHandler response =
    match response with
    | Ok audio -> SetAudio audio
    | Error msg -> msg |> AddError

/// Helper to load audio and music and generate variations
let loadAudioAndScore (settings:Settings) (pattern:Pattern) =

    let pattern, isSubdivision =
        if pattern.Pattern.Contains "R" then
            let p' =
                pattern.Pattern.ToUpper()
                |> Seq.map(fun c ->
                    match c with
                    | 'R' -> "KK"
                    | 'D' -> "D."
                    | 'T' -> "T."
                    | 'K' -> "K."
                    | '.' -> ".."
                    | _ -> failwithf "Unknown sound %c in pattern %A" c pattern
                )
                |> String.concat ""

            {pattern with Pattern = p'}, true
        else
            pattern, false

    let query =
        {
            Pattern = pattern.Pattern
            Bpm = if settings.Tempo.IsNone then pattern.Tempo else settings.Tempo.Value
            Repeats = 32
            Metronome = settings.Metronome
            Humanisation = {SampleRandomisation = true; Delay = 10.0}
            Grid = if isSubdivision then pattern.Grid.Subdivision else pattern.Grid
            IsSubdivision = isSubdivision
            Output = Mp3
            Instrument = settings.Instrument
        }


    [
        // load audio
        Cmd.OfAsync.perform server.LoadAudio query generateAudioHandler

        // load the music score
        Cmd.ofMsg (pattern.ToVexFlow() |> SetMusicScore)
        
    ]
    |> Cmd.batch


let update (msg: Msg) (model: Model) : Model * Cmd<Msg> =
    match msg with

    | SetMusicScore score ->
        VexFlowFable.drawScore ("patternView", score.Signature, (100 + 25 * score.Notes.Length), score.Notes)
        {model with Score = Some score}, Cmd.none

    | SetAudio response ->
        {model with
            Audio = response
            IsWaiting = false
            IsAudioPlaying = true
            }, Cmd.none

    | PlayPattern pattern ->
        let specificPattern = pattern.RandomVariation(false)
        {model with NowPlaying = Some specificPattern; IsWaiting = true}, loadAudioAndScore model.Settings specificPattern

    | Authenticate login ->
        let cmd = Cmd.OfAsync.perform server.Authenticate login ProcessAuthResponse
        model, cmd

    | UpdateLoginDetails login ->
        {model with LoginDetails = login}, Cmd.none
    
    | AddError msg ->
        {model with Errors = (System.Guid.NewGuid(), msg)::model.Errors}, Cmd.none

    | ProcessAuthResponse result ->        
        match result with
        | Ok () -> {model with IsAuthenticated = true}, Cmd.none
        | Error msg -> model, msg |> AddError |> Cmd.ofMsg

    | ClearError guidToClear ->
        {model with Errors = model.Errors |> List.filter (fun (guid, _) -> guid <> guidToClear)}, Cmd.none

    | SetPatternsAndBreadcrumbs (patternsAndMeta, breadcrumbs) ->
        {model with
            Patterns = patternsAndMeta
            Breadcrumbs = breadcrumbs
            IsWaiting = false
        }, Cmd.none

    | Expand pattern ->
        let breadcrumbs =
            match model.Breadcrumbs with
            | [] -> [pattern]
            | rhythm::[] -> rhythm::pattern::[]
            | _ -> failwith "Unexpected"

        let cmd = Cmd.OfAsync.perform server.GenerateVariations (pattern, 1000, pattern.IsConcrete) (generateVariationsResponseHandler breadcrumbs)

        {model with IsWaiting = true}, cmd

    | ResetPatterns ->
        let cmd =
            let patterns =
                Balkanica.RhythmLibrary.rhythmsLibrary
                |> Seq.toArray
                |> Array.map (fun (p, m) -> p, Some m)


            SetPatternsAndBreadcrumbs(patterns, [])
            |> Cmd.ofMsg

        model, cmd

    | Goto breadcrumb ->
        let model', pattern =
            match breadcrumb, model.Breadcrumbs with
            | Rhythm, rhythm::_ -> {model with Breadcrumbs = []}, rhythm
            | Variation, rhythm::variation::_ -> {model with Breadcrumbs = rhythm::[] }, variation
            | _ -> failwith "Unexpected"

        let cmd =
            pattern
            |> Expand
            |> Cmd.ofMsg 

        model', cmd

    | SetSearchString s ->
        {model with SearchString = s}, Cmd.none


    | SetInfoPattern patternAndMeta ->
        {model with SelectedPatternForInfo = patternAndMeta}, Cmd.none

    | Search ->
        let cmd =
            // reset
            if model.SearchString = "" then
                ResetPatterns
                |> Cmd.ofMsg

            // rhythm search
            elif (model.SearchString.ToUpper() |> Seq.forall (fun c -> c = 'R' || c = '.' || c = 'D' || c = 'T' || c = 'O' || c = '*' || c = 'K' || c = 'X')) then
                let searchPattern =
                    {
                        Tempo = 150
                        Pattern = model.SearchString
                        Signature = {Numerator = model.SearchString.Length; Denominator = Eight}
                        Grid = Eight
                    }

                Cmd.OfAsync.perform server.GenerateVariations (searchPattern, 1000, false) (generateVariationsResponseHandler [searchPattern])

            elif model.SearchString.Contains "/" then
                let patterns =
                    try
                        let targetSignature = Signature.Parse model.SearchString
                        
                        Balkanica.RhythmLibrary.rhythmsLibrary
                        |> Seq.toArray
                        |> Array.filter (fun (p, m) -> p.Signature = targetSignature)                    
                        |> Array.map (fun (p, m) -> p, Some m)
                    with
                    | _ ->
                        Balkanica.RhythmLibrary.rhythmsLibrary
                        |> Seq.toArray
                        |> Array.map (fun (p, m) -> p, Some m)

                SetPatternsAndBreadcrumbs(patterns, [])
                |> Cmd.ofMsg

            // name search
            else
                let searchString = model.SearchString.ToLower()

                let patterns =
                    Balkanica.RhythmLibrary.rhythmsLibrary
                    |> Seq.toArray
                    |> Array.filter (fun (_, m) -> m.Name.ToLower().Contains searchString || (m.Alias |> Array.exists (fun alias -> alias.ToLower().Contains searchString)))                    
                    |> Array.map (fun (p, m) -> p, Some m)
                    
                SetPatternsAndBreadcrumbs(patterns, [])
                |> Cmd.ofMsg
        model, cmd

    | ToggleSettingsModal flag ->
        {model with IsSettingsOpen = flag}, Cmd.none

     | ToggleAudioPlaying flag ->
        {model with IsAudioPlaying = flag}, Cmd.none

    | UpdateSettings settings ->
        let cmd =
            match model.NowPlaying with
            | Some p -> p |> PlayPattern |> Cmd.ofMsg // reset the audio in case the settings are different
            | None -> Cmd.none

        {model with Settings = settings}, cmd


let init () : Model * Cmd<Msg> =
    Model.Init, Cmd.none//Server.getStatsCmd