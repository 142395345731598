module Balkanica.DrumPattern


type NoteValue =
    | Quarter
    | Eight
    | Sixteenth

    /// numerical representation of the inverse of the note length
    member this.value =
        match this with 
        | Quarter   -> 4
        | Eight     -> 8
        | Sixteenth -> 16


    member this.Subdivision =
        match this with 
        | Quarter   -> Eight
        | Eight     -> Sixteenth
        | Sixteenth -> failwith "Cannot sub-divide 16th for now"


    /// expressed as a number of notes of this value in a bar of 16 (to avoid floats)
    member this.fraction = 16 / this.value
        
    static member Parse (x:string) =
        match x.Trim() with
        | "16" -> Sixteenth
        | "8" -> Eight
        | "4" -> Quarter
        | _ -> failwithf "Unexpected note value %s" x

type VexFlowNotes =
    {
        Note: string
        Duration : string
        IsGrace : bool
        Label : string
    }

type VexFlowScore =
    {
        Signature : string
        Pattern : string
        Notes : VexFlowNotes[]
    }

(*
       "D" -> "Doum"
       "K" -> "Ka"
       "T" -> "Tek"
       "." -> "Rest"
       "S" -> "Doum" or "Tek" (strong)
       "W" -> "Ka" or "Rest" (weak)
       "*" ->  any
*)

type Signature =
    {
        Numerator : int
        Denominator : NoteValue
    }

    override this.ToString() = $"{this.Numerator}/{this.Denominator.value}"

    /// Length of a bar as number of 16ths
    member this.Length = this.Numerator * this.Denominator.fraction

    static member Parse (x:string) =
        let fields = x.Split("/")
        {
               Numerator = (int fields.[0])
               Denominator = fields.[1] |> NoteValue.Parse
        }


type Pattern =
    {
        Tempo     : float // BPM
        Pattern   : string
        Grid      : NoteValue
        Signature : Signature
    }

    override this.ToString() =
        $"{this.Signature.ToString()}[1/{this.Grid.value}]{this.Pattern}"


    static member Default =
        {
            Pattern = String.replicate 8 "*"
            Grid = Eight
            Signature = "4/4" |> Signature.Parse
            Tempo = 150.0
        }

    /// The simplest possible variation consistent with the pattern
    member this.SimplestVariation() =
        this.Pattern.Replace("o", ".").Replace("x", "T").Replace("*", ".")
        |> fun p -> {this with Pattern = p}

    /// A random variation consistent with the pattern
    member this.RandomVariation (includeKa:bool) =
        let rnd = System.Random()

        this.Pattern
        |> Seq.map(fun c ->
            match c with
            | 'o' -> if includeKa && rnd.NextDouble() > 0.8 then "K" else "."
            | 'x' -> if rnd.NextDouble() > 0.7 then "D" else "T"
            | '*' ->
                let r = rnd.NextDouble()
                if r < 0.25 then "D"
                elif r >= 0.25 && r < 0.5 then "T"
                elif r >= 0.5 && r < 0.75 then "K"
                else "."
            | _ -> string c
        )
        |> String.concat ""
        |> fun p -> {this with Pattern = p}

    /// Generates a sub-divided pattern that allows for any variations
    member this.SubdivideAny () =
        {
            Pattern = this.Pattern |> Seq.map (fun c -> $"{c}*") |> String.concat ""
            Grid = this.Grid.Subdivision
            Signature = this.Signature
            Tempo = this.Tempo
        }

    /// Generates a sub-divided pattern that allows for the common variations where Ks are doubled
    member this.SubdivideCommon () =
        {
            Pattern = this.Pattern |> Seq.map (fun c -> if c = 'K' then $"Ko" else $"{c}.") |> String.concat ""
            Grid = this.Grid.Subdivision
            Signature = this.Signature
            Tempo = this.Tempo
        }

    /// Length of the pattern expressed as number of 16ths
    member this.Length = this.Pattern.Length  * 16 / this.Grid.value

    /// Checks if the signature length mattches the pattern length
    member this.IsValid = this.Signature.Length = this.Length

    /// Simple complexity metric defined as the number of non-rest notes
    member this.Complexity = this.Pattern.Replace(".", "").Length


    static member IsWeak c =
        match c with
        | '.' | 'K' | 'R' | 'o' -> true
        | 'D' | 'T' | 'x' -> false
        | _ -> failwithf "Unexpected symbol %c" c

    static member IsStrong c =
        match c with 
        | '.' | 'K' | 'R' | 'o' -> false
        | 'D' | 'T' | 'x' -> true
        | _ -> failwithf "Unexpected symbol %c" c

    /// Check if two patterns have an overlap.
    static member Intersect (pattern:Pattern) (pattern':Pattern) =
        if pattern.Grid = pattern'.Grid &&
            pattern.Pattern.Length = pattern'.Pattern.Length then         
            (pattern.Pattern, pattern'.Pattern)
            ||> Seq.zip
            |> Seq.forall (fun (c, c') ->
                c = c' ||
                (c = '*') || (c' = '*') ||
                (c = 'o' && Pattern.IsWeak c') || (c' = 'o' && Pattern.IsWeak c) ||
                (c = 'x' && Pattern.IsStrong c') || (c' = 'x' && Pattern.IsStrong c)
            )
        else
            false

    /// Check if pattern is a subset of pattern'.
    static member IsSubsetOf (pattern:Pattern) (pattern':Pattern) =
        if pattern.Grid = pattern'.Grid &&
            pattern.Pattern.Length = pattern'.Pattern.Length then         
            (pattern.Pattern, pattern'.Pattern)
            ||> Seq.zip
            |> Seq.forall (fun (c, c') ->
                c = c' ||  (c' = '*') || (c' = 'o' && Pattern.IsWeak c) ||  (c' = 'x' && Pattern.IsStrong c)
            )
        else
            false

    member this.intersects pattern =
        Pattern.Intersect this pattern

    member this.isSubsetOf pattern =
        Pattern.IsSubsetOf this pattern

    /// The pattern is concrete (e.g. describes only a single variation)
    member this.IsConcrete = not (this.Pattern.Contains "*" || this.Pattern.Contains "x" || this.Pattern.Contains "o")

    member this.ToVexFlow () =      
        if not this.IsConcrete then
            printfn $"WARNING: Only concrete patterns should be viasualised {this.Pattern}"

        // map the pattern symbols to (note * length * isGrace)
        let keyMap duration =
            [|
                'D',{ Note = "c/4"; Duration = $"{duration}"; IsGrace = false; Label =  "D"}
                'T',{ Note = "a/4"; Duration = $"{duration}"; IsGrace = false; Label =  "T"}
                'K', { Note = "c/5"; Duration = $"{duration}"; IsGrace = true; Label =  "k"}
                '.',{ Note = "a/4"; Duration = $"{duration}r"; IsGrace = false; Label =  ""}
            |]
            |> Map.ofSeq

        let absorbRests c (duration:int) =
            let noteAndRests =
                System.Convert.ToString(duration, 2)
                |> Seq.rev
                |> Seq.indexed
                |> Seq.choose(fun (i, f) ->
                    if f='1' then
                        Some (int (16.0 / (2.0 ** (float i))))
                    else
                        None
                    )
                |> Seq.toList
                |> List.rev

            match noteAndRests with
            | note::rests ->                        
                (keyMap note).[c]::(rests |> List.map (fun d -> (keyMap d).['.']))
            | _ -> failwith "Not expected"

        this.Pattern + "K" //add a dummy letter at the end to mark the position
        |> Seq.indexed
        |> Seq.filter (fun (_, c) -> c = 'D' || c = 'T' || c = 'K')
        |> Seq.pairwise
        |> Seq.collect(fun ((i, c), (i',_)) -> absorbRests c (this.Grid.fraction * (i' - i)))
        |> Array.ofSeq
        |> fun score ->
            {
                Signature = this.Signature.ToString()
                Notes = score
                Pattern = this.Pattern
            }



type Humanisation =
    {
        /// random shift of notes by up to this much
        Delay : float

        /// Random
        SampleRandomisation : bool
    }

type AudioOutput = Wav | Mp3

type Instrument = Darbuka | Davul

type AudioQuery =
    {
        Bpm : float
        Repeats : int
        Metronome : bool
        Humanisation : Humanisation
        Grid : NoteValue
        IsSubdivision : bool
        Pattern: string
        Output: AudioOutput
        Instrument : Instrument
    }

type AudioResponse =
    {
        Pattern : string
        Midi: byte[]
        Mp3: byte[]
    }
    static member empty =
        {
            Pattern = ""
            Midi = Array.empty
            Mp3 = Array.empty
        }



type Region =
    | MiddleEast
    | Balkan
    | Western
    | World

    override this.ToString() =
        match this with 
        | MiddleEast -> "Middle East"
        | Balkan     -> "Balkans"
        | Western    -> "Western"
        | World      -> "World"

type Meta =
    {
        Name : string

        
        //Variation: string

        // Other names or spellings
        Alias : string[]

        // 
        Notes : string

        // Origin (geography)
        Region :Region

        /// Typical tempo
        Tempo : int

        /// Typical variations
        Variations: string[]

        // External resources (links)
        Info : string[]
        Tutorials : string[]
        Examples : string[]
        Videos : string[]
        
    }

    static member Create name =
        {
            
            Name = name
            //Variation = ""
            Alias = Array.empty
            Notes = ""
            Info = Array.empty
            Tutorials = Array.empty
            Examples = Array.empty
            Variations = Array.empty
            Tempo = 100
            Region = World
            Videos = Array.empty
        }
 